div#sidemenu{
    position: relative;
    height:100%;
    width: max-content;
    border-left:solid #DFDDDC 1px;
    border-right:solid #DFDDDC 1px;
    float: left;
}
div#sidemenu .col:first-child{
    border-right:solid #DFDDDC 1px;
}
div#sidemenu .col{    
    width:180px;
    height: 100%;
    background-color: white;
    float: left;
    position: relative;
}
div#sidemenu.reset .col{
    height:calc(100% - 50px);
}
div#sidemenu .sidetop{
    height:50px;
    width:100%;
    border:0;
    border-bottom:1px solid #DCDCDC;
    box-sizing: border-box;    
    line-height: 50px;
    font-size: 1em;
    text-transform: capitalize;
    position: relative;
    z-index: 5;
}
div#sidemenu .sidetop:not(.not){
    padding: 0 15px;
}
select {
    appearance: none;
    text-indent: 1px;
    text-overflow: clip;
    outline: none;
}
div#sidemenu .sidetop input, div#sidemenu .sidetop select{
    padding: 0 40px 0 15px;
    box-sizing: border-box;
    border:0;
    height:49px;
    width:100%;
    font-size: 1em;
    text-transform: capitalize;
    background-color: transparent;
}
div#sidemenu select.sidetop{
    width:100%;
}
div#sidemenu .rstInput,div#sidemenu .arrow {
    background-image:url(../icons/x_dark.svg);
    background-repeat: no-repeat;
    background-position: center;
    width:15px;
    height: 15px;
    position: absolute;
    transform: translateY(-50%);
    z-index: 50;
    top:50%;
    right: 20px;
    cursor: pointer;
}
div#sidemenu .arrow {
    background-image:url(../icons/arrow.svg);
    opacity: 0.5;
    transition: all 250ms;
    pointer-events: none;
}
div#sidemenu select {
    cursor: pointer;
}
div#sidemenu select:hover + .arrow {
    opacity: 1;
}


/* Lamp Element button */
div.lamp-elements-parent{
    height: calc(100% - 100px);
    overflow-y: auto;
}
button.lamp-element-content{
    width:100%;
    border:0;
    background-color: transparent;
    overflow: hidden;
    cursor:pointer;
}
button.lamp-element-content h2{
    font-weight: 200;
    margin: 0;
    font-size:1.2em;
    margin-bottom:15px;
}
button.lamp-element-content img{    
    position: relative;
    width:110%;
    left:50%;
    transform:translateX(-50%);    
}
div.lamp-element.filtered button{
    opacity: 0.15;
}
div.lamp-element button{
    transition: opacity 0.2s;
}
div.lamp-element{
    position: relative;    
}
div.lamp-element img{
    transition: opacity 0.2s;
}
div.lamp-element .name{
    text-transform: uppercase;
}
div.lamp-element:not(.active):not(.filtered):hover img{
    opacity: 0.5;
}
div.lamp-element.active{
    z-index: 50;
    background-color: #F8F4F2!important;
    position: sticky;
    top:0;
    bottom:0;
    box-shadow: 0px 0px 20px 16px #ffffff;
}
div.lamp-element.active:not(:first-child) button.lamp-element-content{
    border-top:#DFDDDC dashed 1px;
}
div.lamp-element.active:not(:last-child) button.lamp-element-content{
    border-bottom:#DFDDDC dashed 1px;
}
div.lamp-element.active:first-child button.lamp-element-content{
    margin:0!important;
    margin-bottom:-1px!important;
}
div.lamp-element.active:last-child button.lamp-element-content{
    margin:0;
    margin-top:-1px;
}
div.lamp-element.active button.lamp-element-content{
    margin-top:-1px;
    margin-bottom:-1px;
}
div.lamp-element:hover div.actions span.btn,div.lamp-element.active div.actions span.btn{
    opacity: 1;
}
div.lamp-element div.actions span.btn:hover span.label{
    display: block;
}
div.lamp-element div.actions{
    position: absolute;    
    top:0;
    /* z-index: 50; */
}
div.lamp-element div.actions span.btn {
    width:16px;
    height:16px;
    display: block;    
    background-size:15px;
    background-repeat:no-repeat;
    background-position: center;
    margin:6px;
    cursor: pointer;
    opacity:0;
    transition: opacity 0.2s;
}
div.lamp-element div.actions span.btn.file{
    background-image:url("../icons/file.svg");
}
div.lamp-element div.actions span.btn.pdf{
    background-image:url("../icons/pdf.svg");
}
div.lamp-element div.actions .label{
    display: block;
    color:white;
    padding: 4px 10px;
    background:#333;
    border-radius: 5px;
    width: max-content;
    margin-left:30px;
    position: relative;
    pointer-events: none;
    top:-6px;
    display: none;
    z-index: 500;
}
div.lamp-element div.actions .label::before{
    content:"";
    border-right:5px solid #333;
    border-bottom: 5px solid transparent;
    border-top: 5px solid transparent;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left:-5px;
}
button.lamp-element-content img, button.lamp-element-content h2{
    pointer-events: none;
}

/* Hide scrollbar for Chrome, Safari and Opera */
#sidemenu .col .lamp-elements-parent::-webkit-scrollbar {
    display: none;
}
  
  /* Hide scrollbar for IE, Edge and Firefox */
#sidemenu .col  .lamp-elements-parent{
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */    
}

#sidemenu .col.required::before{  
    content: "";    
    position: absolute;
    left:10px;
    bottom:10px;
    right:10px;
    top:110px;
    pointer-events: none;
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
	transform: scale(1);
	animation: pulse 2s infinite;
    z-index: 100;
}
#sidemenu .col.required::after{  
    content: "";    
    position: absolute;
    width:5px;
    height: 5px;
    background:red;
    border-radius: 50%;
    right:15px;
    top:115px;
    pointer-events: none;
    z-index: 50;
}

#sidemenu .col .emptyCol{
    text-align: center;
    font-size:0.8em;
    position: relative;
    top:50%;
    transform: translateY(-50%);
    padding:0 10px;
}
#sidemenu #resetCreation{
    position: absolute;
    bottom:0;
    width: 100%;
    text-align: center;
    line-height: 50px;
    height: 50px;
    background-color: white;
    border-top: solid #DFDDDC 1px;
    text-transform: uppercase;
    font-weight: 900;
    cursor: pointer;
    transition: all 0.15s;
    color:black;
    z-index: 50;
    overflow: hidden;
}
#sidemenu #resetCreation.opened{
    line-height: 40px;
    height: 80px;
}
#sidemenu #resetCreation>div:first-child:hover,#sidemenu #resetCreation.opened>div:first-child{
    background-color: rgb(236, 236, 236);
    text-decoration: underline;
}
#sidemenu #resetCreation>div:first-child{
    font-size: 1em;
    transition: all 0.15s;    
}
#sidemenu #resetCreation>div:last-child{
    position: absolute;
    display: flex;  
    width: 100%;
}
#sidemenu #resetCreation>div:last-child div{
    width:50%;
    transition: all 0.15s; 
}
#sidemenu #resetCreation>div:last-child div:hover{
    text-decoration: underline;
}
#sidemenu #resetCreation>div:last-child div:first-child{
    background: #D65B3D;
}
#sidemenu #resetCreation>div:last-child div:first-child:hover{
    background: #ed6746;
}
#sidemenu #resetCreation>div:last-child div:last-child{
    background: #39b739;
}
#sidemenu #resetCreation>div:last-child div:last-child:hover{
    background: #48d548;
}
#sidemenu #resetCreation.opened>div:first-child{
    font-size: 0.75em;
}