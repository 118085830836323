#rightOptionsMobile {
    display: none;
}

.hamburger-react {
    display: none;
}

#bottomMobile {
    display: none;
}


@media screen and (max-width: 1460px) {
    #root #optiontop div.col h4#reset {
        color: transparent;
        font-size: 0;
        background-image: url(../icons/reset.svg);
        background-position: center;
        background-size: 80%;
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        position: relative;
        top: 2px;
    }

    #root #optiontop #confirmReset {
        left: -30px;
        margin-top: 13px;
        transform: translateX(0);
        position: absolute;
        width: 60px;
    }

    #root #optiontop div.col:last-child {
        margin-left: 10px;
        line-height: 18px;
    }
}

@media screen and (max-width: 1380px) {
    #root #optiontop {
        padding: 15px;
    }

    #root #optiontop span.separator {
        margin: 0 15px;
    }

    #root #optiontop div.col h4 {
        font-size: 12px;
    }

    #root #optiontop .topBtn>* {
        font-size: 13px;
        line-height: 20px;
    }

    #root #selectionBar p {
        font-size: 12px;
    }

    /* #root #selectionBar {
        max-width: 150px;
    } */

    #root #optiontop span.selBtn {
        font-size: 0.7em;
    }

    #root #rightOptions {
        right: 10px;
        width: 250px;
    }

    #root #rightOptions .env3d-parent>div {
        height: 130px;
    }

    #root a#poweredByED {
        width: 70px;
        height: 23px;
        bottom: 120px;
        right: 50px;
    }

}

@media screen and (max-width: 1280px) {
    #root #onBoarding div.step h1 {
        font-size: 3em;
        line-height: 50px;
        max-width: 450px;
    }

    #root #onBoarding div.step p.text {
        font-size: 1.25em;
        max-width: 800px;
        margin: 0 auto;
        margin-top: 40px;
        text-align: center;
    }

    #root #onBoarding div.bigButtons button {
        padding: 80px 40px;
        width: 250px;
        font-size: 1.25em;
    }

    #root #onBoarding div.step h2,
    #onBoarding div.step h1 {
        margin-bottom: 30px;
        font-size: 3vw;
        margin-top: -15px;
    }

    #root #onBoarding {
        padding: 20px;
    }
    #root #onBoarding div.filter {
        top: -17px;
    }

    #root .progressBar {
        transform: scale(0.85);
        margin-top: -10px;
        margin-bottom: 5px;
    }

    #root #onBoarding div.step {
        width: calc(100% - 40px);
        height: calc(100% - 100px);
        margin-top: -25px;
        overflow: visible;
    }
    #root #onBoarding div.step.first .selectProduct {
        max-height: calc(100% - 60px);
    }

    #root #bottombar .bottomIcon {
        padding: 0px 15px;
    }

    #root #bottombar .bottomIcon p {
        font-size: 1.25vw;
    }

    #root #topbar h1 {
        font-size: 1.35vw;
    }

    #root #topbar a {
        font-size: 1.15vw;
    }
}


/*------------------------------------------------------------------------------------- TABLET */
@media screen and (max-width: 1024px) {
    #root #mainbody {
        height: calc(100% - 50px);
    }

    #root #selectionBar {
        max-width: inherit;
    }

    #root #rightBody {
        width: calc(100% - 273px);
        left: 273px;
    }

    #root .env3d-side {
        display: none;
    }

    #root #onBoarding #videoBG {
        top: calc(50% + 30px);
    }

    #root #envMeasures {
        bottom: 65px;
        left: 288px;
    }

    /* --------------------------------- On Boarding */

    #root #onBoarding {
        top: 50px;
        padding: 10px;
    }

    #root #onBoarding div.step h2,
    #onBoarding div.step h1 {
        margin-bottom: 50px;
        font-size: 2em;
    }

    #root #onBoarding div.step {
        width: calc(100% - 20px);
        height: calc(100% - 80px);
        margin-top: -25px;
        overflow: visible;
    }

    #root #onBoarding div.selectProduct {
        margin-top: -20px;
    }

    #root #onBoarding div.filter {
        top: -56px;
    }

    #root .progressBar {
        transform: scale(0.65);
        margin-top: -10px;
        margin-bottom: 5px;
    }

    #root #onBoarding div.step .init {
        top: calc(50% + 25px);
        width: 100%;
    }

    #root #onBoarding div.step h2,
    #onBoarding div.step h1 {
        margin-bottom: 30px;
        font-size: 1.75em;
    }

    #root #onBoarding div.step.first .selectProduct {
        max-height: calc(100% - 60px);
    }

    #root #onBoarding div.step.second .selectProduct {
        max-height: calc(100% - 52px);
    }

    #root #onBoarding div.bigButtons button {
        padding: 30px 15px;
        border-radius: 20px;
        width: calc(50% - 40px);
        font-size: 1.25em;
    }

    /* ----------------------------------- Top Menu */

    #root #rightOptions {
        display: none;
    }

    #root #optiontop .topBtn#individual-top {
        margin-right: 20px;
    }

    #root div#topbar {
        height: 50px;
        padding: 0 10px;
        width: calc(100% - 20px);
    }

    #root div#topbar #logo img {
        height: 30px;
    }

    #root div#topbar #logo {
        margin-left: 2px;
    }

    #root div#topbar a {
        line-height: 45px;
    }

    #root div#topbar h1 {
        display: none;
    }

    #root div#topbar #side {
        position: absolute;
        top: 50px;
        left: 0;
        right: 0;
        z-index: 1000;
        background-color: white;
        box-shadow: 0 8px 16px hsl(0deg 0% 4% / 10%);
        transition: height 150ms;
        overflow: hidden;
        height: calc(40px * 5);
        /*<--------------------------------CAN CHANGE | Variable? */
    }

    #root #optiontop div.col h4#reset {
        top: 6px;
    }

    #root #topBG {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 100vh;
        z-index: 999;

    }

    #root div#topbar #side.hideMobile {
        height: 0;
    }

    #root div#topbar #side a {
        display: block;
        color: black;
        text-align: center;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
    }

    #rightOptionsMobile {
        display: block;
    }

    #rightOptionsMobile button {
        padding: 0;
        margin: 0;
        background-color: transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
    }

    #rightOptionsMobile button p {
        font-size: 15px;
    }

    #root .hamburger-react {
        display: block;
        float: right;
        z-index: 600;
    }

    /*------------------------------------------------------------ Option Top  */
    #root #optiontop {
        height: 40px;
        padding: 5px;
    }

    #root #optiontop div.col:not(:last-child):not(:first-child) {
        margin-left: 10px;
        position: relative;
    }

    #root #optiontop h4,
    #root #customColors {
        display: none;
    }

    #root #optiontop .topBtn span,
    #root #optiontop .topBtn svg {
        margin: 0;
        margin-right: 5px;
    }

    #root #optiontop .topBtn .icon {
        height: 17px;
        width: 15px;
        background-size: 16px;
    }

    #root #optiontop .topBtn svg {
        height: 18px;
        width: 20px;
        background-size: 20px;
    }

    #root #optiontop .topBtn p {
        margin-right: 10px;
    }

    #root #optiontop .topBtn {
        border: solid #DFDDDC 1px;
        margin: 0;
        margin-left: 0;
        margin-top: 0;
        display: flex;
        align-items: center;
    }

    #root #optiontop span.selBtn p {
        margin: 0;
    }

    #root #optiontop div.col.required::before {
        left: -5px;
        bottom: -5px;
        right: -5px;
        top: -5px;
    }

    #root #optiontop div.col.required::after {
        right: 5px;
        top: 5px;
    }

    /*------------------------------------------------------------ Bottom bar  */
    #root #bottombar {
        height: 50px;
        padding: 0 15px;
    }

    #root #bottombar .bottomIcon {
        padding: 0px 20px;
        font-size: 12px;
    }

    #root #bottombar .bottomIcon span.icon {
        height: 24px;
        width: 24px;
        margin-right: 10px;
    }

    #root #bottombar #price h5 {
        font-size: 0.5em;
    }

    #root #bottombar #price p {
        font-size: 1em;
    }

    #root a#poweredByED {
        width: 60px;
        height: 20px;
        bottom: 60px;
        right: 15px;
    }

    /*------------------------------------------------------------ iFrame */
    #root #appIframe {
        top: 40px;
        height: calc(100% - 90px);
    }

    /*------------------------------------------------------------ Side menu */
    #root div#sidemenu .col {
        width: 135px;
    }

    #root div#sidemenu .sidetop input,
    #root div#sidemenu .sidetop select {
        padding: 0 40px 0 15px;
        height: 34px;
    }

    #root div#sidemenu .sidetop {
        height: 40px;
        line-height: 40px;
        font-size: 0.75em;
    }

    #root button.lamp-element-content h2 {
        font-size: 0.9em;
        margin-bottom: 10px;
    }

    #root div.lamp-elements-parent {
        height: calc(100% - 80px);
    }

    #root button.lamp-element-content img {
        width: 90%;
        transform: translateX(-56%);
    }

    #root #sidemenu .col.required::before {
        top: 90px;
    }

    #root #sidemenu .col.required::after {
        top: 95px;
    }

    /*------------------------------------------------------------ Side Options */
    #root #sideoptParent {
        width: 273px;
    }

    #root button.bgBtn {
        margin-bottom: 8px;
        padding: 10px;
    }

    #root button.bgBtn span.circle.palette:not(:nth-child(4)) {
        margin-right: -3px;
    }

    #root button.bgBtn span.circle {
        width: 15px;
        height: 15px;
        margin-right: 15px;
        margin-top: 1px;
    }

    #root button.bgBtn p {
        font-size: 0.9em;
    }

    #root button.bgBtn.active::after {
        width: 12px;
        height: 12px;
        margin-top: 2px;
    }

    #root div.validation button {
        font-size: 0.9em;
    }

    #root .topToggleParent {
        height: 35px;
    }

    #root div.toggleMenu p {
        font-size: 0.8em;
        line-height: 35px;
    }

    #root div.toggleMenu .currentColor p {
        font-size: 0.65em;
    }

    #root .popup .popup-prompt {
        width: calc(100% - 31px);
        height: calc(100% - 31px);
        padding: 15px;
        border-radius: 0;
        max-width: 100%;
        max-height: 100%;
    }

    #root .popup .popup-prompt-bottom {
        margin-top: 0;
        position: fixed;
        width: calc(100% - 30px);
        background-color: white;
        padding: 10px 0;
        bottom: 0;
    }

    #root .env3d.popup .popup-prompt-parent {
        width: 100%;
        max-height: calc(100vh - 98px - 72px);
        overflow-y: auto;
    }

    #root .env3d.popup .popup-prompt-parent>div:not(.react-pdf__Document) {
        flex: 1 0 49%;
        max-width: calc(50% - 5px);
    }

}

/*------------------------------------------------------------------------------------- PHONE */

@media screen and (max-width: 950px) {

    /* ----------------------------- Top Options */
    #root #selectionBar {
        display: none;
    }

    #root #optiontop {
        display: flex;
        justify-content: center;
        height: 50px;
        padding: 0;
    }

    #root #optiontop .topBtn {
        margin-top: 0;
        height: 30px;
    }

    #root #optiontop .separator {
        display: none;
    }

    #root #optiontop .topBtn .icon,
    #root #optiontop .topBtn svg {
        height: calc(100% - 12px);
        width: 25px;
        background-size: 18px;
    }

    #root #optiontop .topBtn#individual-top {
        margin-right: 10px;
    }

    #root #optiontop div.col h4#reset {
        background-size: 40%;
        width: 20px;
        height: 30px;
        padding: 6px 10px;
        top: 2px;
        border: solid #D65B3D 1px;
        border-radius: 4px;
    }

    #root #optiontop #confirmReset {
        left: -35px;
        margin-top: 13px;
        width: 90px;
    }

    #root #optiontop #confirmReset span {
        width: 30px;
        height: 30px;
        background-size: 60%;
    }

    #root #envMeasures {
        bottom: auto;
        top: 109px;
        left: 8px;
    }

    /* ------------------------------- Top menu */
    #root #rightOptionsMobile button,
    #root div#topbar #side a {
        height: 50px;
        line-height: 50px;
    }

    #root #rightOptionsMobile button p {
        color: black;
    }

    #root div#topbar #side {
        height: calc(50px * 5);
    }

    /* -------------------------------  */
    #root .popup-prompt-bottom .accept {
        color: black;
    }

    #root #appIframe {
        position: fixed;
        top: 100px;
        height: calc(100% - 150px);
    }

    #root button.lamp-element-content img {
        width: 150px;
        margin: 0 auto;
        left: 0;
        transform: translateX(0);
    }

    #root #bottomMobile {
        display: flex;
        z-index: 998;
        position: fixed;
        bottom: 0;
        background: white;
        height: 50px;
        width: 100%;
        left: 0;
        border-top: 1px solid #dfdad7;
        box-shadow: 0 0 5px rgb(0 0 0 / 10%);
    }

    #root #bottomMobile .bottomButton {
        color: #404040;
        font-size: 1em;
        width: 50%;
        align-items: center;
        cursor: pointer;
        display: flex;
        font-weight: 600;
        justify-content: center;
        position: relative;
    }

    #root #bottomMobile .bottomButton:first-child {
        border-right: 1px solid #dfdad7;
    }

    #root #bottomMobile .bottomButton p::first-letter {
        text-transform: capitalize;
    }

    #root #bottomMobile .bottomButton.active p {
        color: #d65b3e;
    }

    #root #bottomMobile .bottomButton.active::before {
        background-color: #d65b3e;
        content: "";
        height: 2px;
        left: 0;
        position: absolute;
        top: -1px;
        left: -1px;
        width: calc(100% + 2px);
    }

    #root #bottomMobile .bottomButton.required:not(.active)::before {
        content: "";
        position: absolute;
        left: 10px;
        bottom: 10px;
        right: 10px;
        top: 10px;
        pointer-events: none;
        box-shadow: 0 0 0 0 rgb(0, 0, 0);
        transform: scale(1);
        animation: pulse 2s infinite;
        z-index: 100;
    }

    #root #bottomMobile .bottomButton.required:not(.active)::after {
        content: "";
        position: absolute;
        width: 5px;
        height: 5px;
        background: red;
        border-radius: 50%;
        right: 15px;
        top: 15px;
        pointer-events: none;
        z-index: 50;
    }


    #root #rightBody {
        width: calc(100% - 0px);
        left: 0;
    }

    #root #invalidSelection p {
        max-width: 100%;
        padding: 0 20px;
    }

    #root #mainbody {
        height: calc(100vh - calc(100vh - 100%) - 50px);
    }

    #root a#poweredByED {
        /*width: 46px;
        height: 15px;*/
        bottom: 57px;
        right: 135px;
    }

    /* --------------------------- Bottom bar */
    #root #bottombar {
        position: fixed;
        height: 40px;
        padding: 0 8px;
        bottom: 60px;
        background-color: transparent;
        border: 0;
    }

    #root #bottombar .bottomIcon {
        padding: 11px;
        border-radius: 4px;
    }

    #root #bottombar .bottomIcon span.icon {
        height: 26px;
        width: 26px;
        margin-right: 0;
    }

    #root #bottombar .bottomIcon p {
        display: none;
    }

    #root #bottombar #price h5 {
        font-size: 0.65em;
    }

    #root #bottombar #price p {
        font-size: 1.2em;
    }

    #root #bottombar div#price {
        margin-top: 5px;
    }

    #root #bottombar #price h5 {
        color: #00000066;
    }

    #root #bottombar #price h6 {
        color: #00000084;
    }

    /* --------------------------- Side Menu */
    #root div#sidemenu {
        z-index: 998;
        width: calc(100% - 2px);
        height: 100%;
        left: 0;
        transition: all 350ms cubic-bezier(0.25, 0.1, 0.25, 1);
        overflow: hidden;
    }

    #root div#sidemenu.active {
        left: -100%;
    }

    #root div#sidemenu .col {
        width: calc(50% - 1px);
    }

    #root div#sidemenu .sidetop {
        height: 50px;
        line-height: 50px;
        font-size: 0.9em;
    }

    #root div#sidemenu .sidetop input,
    #root div#sidemenu .sidetop select {
        height: 49px;
    }

    #root #sideoptParent.toggled {
        left: 0;
        width: 0;
    }

    #root div.lamp-element div.actions span.btn {
        width: 25px;
        height: 25px;
        display: block;
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
        margin: 10px;
        cursor: pointer;
    }

    #root div.lamp-element div.actions {
        top: 50%;
        transform: translateY(-50%);
    }

    #root div#sidemenu .rstInput,
    #root div#sidemenu .arrow {
        width: 35px;
        height: 35px;
        right: 5px;
        background-size: 35%;
    }

    #root #sidemenu .col.required::before {
        top: 110px;
    }

    #root #sidemenu .col.required::after {
        top: 115px;
    }

    /* --------------------------- Side Options */
    #root #sideoptParent {
        width: 100%;
        height: 100%;
        overflow: auto;
    }

    #root button.bgBtn {
        margin-bottom: 10px;
        padding: 16px;
    }

    #root button.bgBtn span.circle {
        width: 20px;
        height: 20px;
        margin-right: 20px;
        margin-top: 0px;
    }

    #root button.bgBtn p {
        font-size: 1.2em;
        line-height: 19px;
    }

    #root button.bgBtn.active::after {
        width: 18px;
        height: 18px;
        margin-top: 2px;
    }

    #root div.validation button {
        font-size: 0.9em;
        padding: 8px 25px;
    }

    #root .validation {
        height: 40px;
        padding: 10px 20px;
    }

    #root .topToggleParent {
        height: 50px;
    }

    #root div.toggleMenu p {
        font-size: 1em;
        line-height: 50px;
    }

    #root div.toggleMenu .currentColor p {
        font-size: 0.8em;
    }

    #root div.toggleMenu .currentColor span.circle {
        height: 15px;
        width: 15px;
    }

    #root div.toggleMenu .currentColor span.arrow {
        width: 12px;
    }

    #root .toggleMenu[data-type="coating"] button.mtlBtn {
        width: 61px;
        height: 35px;
    }

    #root div[data-type="coating"] svg {
        width: 61px;
        height: 35px;
    }

    #root .bottomToggleParent {
        grid-template-columns: repeat(auto-fill, 61px);
        grid-gap: 10px 15px;
    }

    #root div.toggleMenu.toggled .bottomToggleParent button {
        height: 0;
    }

    #root div.toggleMenu.toggled .bottomToggleParent {
        grid-gap: 0 15px;
    }

    #root .toggleMenu[data-type="other"] button.mtlBtn {
        width: 61px;
        height: 35px;
    }

    #root .toggleMenu button.mtlBtn.active::after {
        width: 30px;
        height: 30px;
        top: -10px;
        right: -6px;
    }

    #root #sideoptParent h4 {
        padding: 15px 20px;
        margin: 0;
        top: 0;
        position: sticky;
        z-index: 500;
        background-color: white;
        font-size: 1.25em;
        /*border-bottom:1px #DFDDDC solid;*/
    }

    #root #sideoptParent .BGopt h4 {
        padding: 15px 0px;
    }

    /* --------------------------------------------------------- Integration popup */

    #root .popup .popup-prompt-top h2 {
        font-size: 1.3em;
    }

    #root .popup .popup-prompt-top p {
        font-size: 0.8em;
    }

    #root .popup .popup-prompt-bottom {
        margin-top: 0;
        position: fixed;
        width: calc(100% - 30px);
        background-color: white;
        padding: 10px 0;
        bottom: 0;
    }

    #root .popup .popup-quit {
        top: 10px;
        right: 10px;
    }

    #root .popup-textfield {
        width: 100%;
    }

    #root .popup-textfield.space {
        margin-right: 0;
    }

    #root .popup-bottomButton {
        position: fixed;
        width: calc(100% - 30px);
        bottom: 15px;
    }

    #root .popup-prompt-material {
        width: calc(100% - 32px);
    }

    #root .popup-prompt-material:first-child {
        margin-bottom: 25px;
    }

    #root .popup .popup-prompt-parent-material {
        flex-direction: column;
    }

    #root .popup .popup-prompt-parent.padding {
        padding-bottom: 30px;
        height: calc(100% - 165px);
    }

    #root .popup .popup-prompt-parent-material {
        margin-bottom: 0;
    }

    #root #sidemenu #resetCreation {
        position: fixed;
        bottom: 50px;
        /*height: calc(100% - 70px);*/
    }

    /*#root div#sidemenu.reset .col{
        height:calc(100% - 120px);
    }*/
}

@media screen and (max-width: 600px) {
    #root #onBoarding div.step h1 {
        font-size: 2em;
        line-height: 35px;
        max-width: 450px;
        margin: 20px auto;
        margin-top: 0;
    }

    #root #onBoarding div.step p.text {
        font-size: 1em;
        max-width: 450px;
        margin-top: 40px;
    }

    #root #onBoarding div.step button.startButton {
        margin: 0;
        width: 80%;
        font-size: 1em;
        padding: 15px 0;
        bottom: 0;
        right: 0;
        text-align: center;
    }

    #root #onBoarding div.filter {
        top: 0;
        position: relative;
        justify-content: center;
    }

    #root #onBoarding div.step h2,
    #onBoarding div.step h1 {
        margin-bottom: 35px;
    }

    #root #onBoarding div.selectProduct {
        grid-template-columns: repeat(auto-fill, 100px);
        grid-gap: 10px 10px;
        max-height: calc(100% - 110px);
        margin-top: 0;
    }

    #root #onBoarding div.selectProduct button.btn {
        height: 70px;
        width: 100px;
    }

    #root #onBoarding div.selectProduct button.btn img {
        height: 50px;
    }

    #root #onBoarding div.selectProduct button.btn h3 {
        font-size: 8.5px;
        margin-top: -1px;
    }

    #root #onBoarding div.bigButtons button {
        padding: 40px 20px;
        border-radius: 20px;
        width: 100%;
    }

    #root #onBoarding div.bigButtons button:first-child {
        margin-bottom: 20px;
    }

    #root #onBoarding div.bigButtons {
        flex-direction: column;
    }

    #root .env3d.popup .popup-prompt-parent>div:not(.react-pdf__Document) {
        flex: auto;
        max-width: 100%;
    }

    #root #onBoarding div.step {
        margin-top: 0px;
    }

    #root #onBoarding div.step.first .selectProduct {
        max-height: calc(100% - 110px);
    }

    #root #onBoarding div.step.second .selectProduct {
        max-height: calc(100% - 52px);
    }

    #root #onBoarding div.step h2,
    #onBoarding div.step h1 {
        margin-bottom: 0px;
    }
}

@media screen and (orientation: portrait) and (max-width: 600px) {
    #root #onBoarding div.step.first .selectProduct {
        max-height: calc(100% - 155px);
    }

    #root #onBoarding div.step.second .selectProduct {
        max-height: calc(100% - 95px);
    }
}

/* -------------------------------------------- MOBILE ALERT */

#mobileAlert {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.785);
    backdrop-filter: blur(10px);
}

#mobileAlert>div {
    position: absolute;
    top: 25px;
    left: 25px;
    right: 25px;
    bottom: 25px;
    background-color: #ffeedd;
    border-radius: 3px;
    overflow: hidden;
    padding: 20px;
}

#mobileAlert>div div.flexMobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    justify-content: center;
}

#mobileAlert div img {
    color: #ffffff;
    width: 150px;
}

#mobileAlert div p {
    color: #8c4630;
    text-align: center;
    font-size: 1.25em;
    margin: 0;
    margin-top: 15px;
}

#mobileAlert div h3 {
    text-align: center;
    font-size: 1.8em;
    margin-top: 0;
    margin-bottom: 10px;
}

#mobileAlert div div div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 auto;
    margin-bottom: 50px;
}

#mobileAlert div button {
    border: 3px #8c4630 solid;
    background-color: transparent;
    outline: none;
    color: #8c4630;
    font-weight: 900;
    font-size: 1em;
    border-radius: 6px;
    padding: 15px 25px;
    display: block;
    margin: 0 auto;
}

#mobileAlert span {
    color: #8c4630;
    position: absolute;
    font-size: 30px;
    line-height: 35px;
    font-weight: 900;
    text-align: center;
    top: 10px;
    right: 5px;
    width: 35px;
    height: 35px;
}

@media (orientation: landscape) {

    #root #mobileAlert div h3,
    #root #mobileAlert div p {
        text-align: left;
    }

    #mobileAlert div div div p {
        margin-left: 25px;
        margin-top: 0;
    }

    #mobileAlert div div div {
        margin: 0;
        margin-bottom: 20px;
        flex-direction: row;
    }
}