@font-face {
    font-family: WorkSans;
    src: url('../fonts/WorkSans-Regular.ttf');
    font-weight: 400;
}

@font-face {
    font-family: Kaisei;
    src: url('../fonts/KaiseiHarunoUmi-Bold.ttf');
}

body,
p,
a,
h1,
h2,
h3,
h4,
h5,
h6,
select,
input,
button {
    font-family: WorkSans !important;
}

#mainbody {
    height: calc(100% - 80px);
    position: relative;
}

#rightBody {
    width: calc(100% - 363px);
    height: 100%;
    position: relative;
    left: 363px;
}

#productIframe {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
    position: absolute;
}

#appIframe {
    top: 100px;
    width: 100%;
    height: calc(100% - 200px);
    border: none;
    position: absolute;
    left: 0;
}
#appIframe #application-canvas{
    cursor:hand;
}

#invalidSelection {
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: #FCFAF8;
    z-index: 50;
}

#invalidSelection p {
    max-width: 380px;
    text-align: center;
    margin: auto;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    color: #545454;
}

/* ------------------------------------- Right option */

#rightOptions {
    position: absolute;
    right: 50px;
    top: 110px;
    width: 320px;
    height: 100px;
    z-index: 20;
    transition: all 0.25s;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-height: calc(100% - 270px); 
}
#rightOptions.envInvisible {
    align-items: flex-end;
}

#rightOptions.active {
    /* height: fit-content; */
    height: calc(180px * calc(var(--bgValues) - 1) + 160px + 130px); 
}

#rightOptions button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: 0;
    outline: none;
    display: block;
    cursor: pointer;
    margin-top: 10px;
    margin-left: 15px;
}

#rightOptions button p,
#rightOptionsMobile button p {
    margin: 0;
    font-size: 15px;
    text-align: right;
    display: inline-block;
}

#rightOptions button p::first-letter,
#rightOptionsMobile button p::first-letter {
    text-transform: capitalize;
}

#rightOptions button span,
#rightOptionsMobile button span {
    background-position: center;
    background-repeat: no-repeat;
    width: 20px;
    height: 20px;
    display: inline-block;
    margin-left: 10px;
    position: relative;
    top: 4px;
}

#rightOptions button.export3d span,
#rightOptionsMobile button.export3d span {
    background-image: url(../icons/export.svg);
}

#rightOptions button.chat span,
#rightOptionsMobile button.chat span {
    background-image: url(../icons/chat.svg);
}

#rightOptions button.env3d span,
#rightOptionsMobile button.env3d span {
    background-image: url(../icons/home.svg);
}

#rightOptions button.closeenv3d span,
#rightOptionsMobile button.closeenv3d span {
    background-image: url(../icons/x.svg);
    background-size: 60%;
}

#rightOptions .mobileMenu {
    display: none;
}

#rightOptionsMobile .mobileMenu {
    display: block;
}

#rightOptions .env3d-side.active {
    background-color: #ffffff;
    opacity: 0.35;
}

#rightOptions .env3d-side {
    background-color: transparent;
    border-radius: 8px;
    padding-right: 10px;
    margin-top: 10px;
    height: calc(100% - 45px);
    overflow: hidden;
    transition: all 0.25s;
    flex-grow: 1;
    width: calc(100% - 10px);
}

#rightOptions .env3d-side:hover {
    opacity: 1;
}

#rightOptions h2,
#rightOptions h4 {
    margin: 0;
    text-transform: capitalize;
}

#rightOptions h4 {
    bottom: 0;
    width: calc(100% - 16px);
    text-align: center;
    padding: 8px;
    background-color: #000000b9;
    position: absolute;
    color: white;
    font-size: 13px;
    font-weight: 300;
}

#rightOptions .bottom {
    width: calc(100% - 10px);
    background: rgb(234, 234, 234);
    padding: 10px;
    text-align: center;
    cursor: pointer;
    margin-top: 10px;
    opacity: 0;
    transition: opacity 0.25s;
}

#rightOptions.active .bottom {
    opacity: 1;
}



#rightOptions h2 {
    font-size: 15px;
    font-weight: 100;
}

#rightOptions .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    width: calc(100% - 25px);
    cursor: pointer;
    border-radius: 8px;
}


#rightOptions .env3d-side.active h2 {
    font-weight: 600;
}

#rightOptions .env3d-side h2 {
    text-transform: inherit;
}

#rightOptions .env3d-side .bottom h2 {
    color: rgb(80, 80, 80);
    font-weight: 300;
    font-size: 0.8em;
}

#rightOptions .env3d-side h2::first-letter {
    text-transform: capitalize;
}

#rightOptions .titles div {
    display: flex;
    align-items: center;
    cursor: pointer;
}

#rightOptions .titles div span {
    margin-right: 5px;
    font-size: 12px;
    text-transform: capitalize;
    font-weight: 600;
}

#rightOptions .titles div:hover span {
    text-decoration: underline;
}

#rightOptions .env3d-parent {
    overflow-y: auto;
    height: calc(100% - 65px);
    margin-left: 15px;
    padding-right: 10px;
    opacity: 0;
    transition: opacity 0.25s;
}

#rightOptions.active .env3d-parent {
    opacity: 1;
}

#rightOptions .env3d-side.exitBtn .env3d-parent {
    height: calc(100% - 100px);
}

#rightOptions .env3d-parent>div {
    height: 160px;
    position: relative;
    opacity: 0.7;
    transition: opacity 0.25s;
    cursor: pointer;
    border-radius: 5px;
    overflow: hidden;
}

#rightOptions .env3d-parent>div.disabled {
    opacity: 0.3;
    cursor: auto;
}

#rightOptions .env3d-parent>div:hover:not(.disabled),
#rightOptions .env3d-parent>div.active {
    opacity: 1;
}

#rightOptions .env3d-parent>div:not(:last-child) {
    margin-bottom: 20px;
}

#rightOptions .env3d-parent>div.active {
    opacity: 1;
}

#rightOptions .env3d-parent>div.active::after {
    content: "";
    position: absolute;
    display: block;
    width: 19px;
    height: 19px;
    top: 6px;
    right: 6px;
    background-image: url(../icons/checkmark2.svg);
    transition: opacity 250ms;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;

}

#rightOptions .env3d-parent>div.active h4 {
    font-weight: 600;
}

#rightOptions .env3d-parent>div.active .popup-prompt-thumbnail {
    border: 3px solid #0a0a0adb;
}

#rightOptions .env3d-parent>div .popup-prompt-thumbnail {
    height: 100%;
    box-sizing: border-box;
    border-radius: 5px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

*.animate {
    transition: all 0.20s !important;
}

/* ----------------------------------- Env measures */
#envMeasures {
    position: fixed;
    bottom: 115px;
    z-index: 50;
    left: 414px;
    width: auto;
    background-color: #ffffff44;
    padding: 5px;
    border-radius: 5px;
    cursor: default;
    transition: all 0.15s;
    backdrop-filter: blur(15px);
}

#envMeasures:hover {
    background-color: #ffffff89;
}

#envMeasures tr {
    background-color: transparent;
    border: 0;
}

#envMeasures tr td {
    font-size: 0.65em;
    line-height: 15px;
    color: #000000;
    padding: 0;
    border: 0;
}

#envMeasures tr td:first-child {
    text-align: right;
    font-weight: 300;
}

#envMeasures tr td:last-child {
    padding-left: 5px;
    font-weight: 600;
}


/* ------------------------------------- Error page */
#errorPage {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999999;
    background-color: rgb(255, 255, 255);
}

#errorPage div {
    margin: auto;
    text-align: center;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#errorPage p::first-letter {
    text-transform: capitalize;
}

/* ------------------------------------- Logo EDGE */

a#poweredByED {
    width: 82px;
    height: 27px;
    position: fixed;
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100%;
    bottom: 120px;
    right: 50px;
    z-index: 10;
    opacity: 0.2;
    transition: opacity 0.15s;
}

a#poweredByED[data-lang="lang-en"] {
    background-image: url(../../public/images/logo-edge_en.png);
}

a#poweredByED[data-lang="lang-fr"] {
    background-image: url(../../public/images/logo-edge_fr.png);
}

a#poweredByED:hover {
    opacity: 0.5;
}