#optiontop {
    position: absolute;
    height: 100px;
    width: 100%;
    border-bottom: solid #DFDDDC 1px;
    box-sizing: border-box;
    padding: 15px 50px;
    transition: all 250ms;
    display: flex;
}

#optiontop span.separator {
    width: 1px;
    height: 100%;
    background: #DFDDDC;
    margin: 0 35px;
}

#optiontop.toggled {
    height: 0;
    padding: 0 50px;
}

#optiontop div.col {
    float: left;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
/* #optiontop div.col.reset {
    justify-content: flex-start;
} */

#optiontop div.col.required::before {
    content: "";
    position: absolute;
    left: -20px;
    bottom: -10px;
    right: -20px;
    top: -10px;
    pointer-events: none;
    box-shadow: 0 0 0 0 rgb(0, 0, 0);
    transform: scale(1);
    animation: pulse 2s infinite;
}

#optiontop div.col.required::after {
    content: "";
    position: absolute;
    width: 5px;
    height: 5px;
    background: red;
    border-radius: 50%;
    right: 0px;
    top: 5px;
    pointer-events: none;
}

#optiontop div.col.reset {
    margin-left: 20px;
}

#optiontop div.col h4 {
    margin: 0;
    font-weight: 200;
}

#optiontop span.selBtn {
    border: solid #DFDDDC 1px;
    padding: 5px 13px;
    border-radius: 16px;
    font-size: 0.9em;
    display: flex;
    align-items: center;
    width: fit-content;
}
#optiontop .flexButtons {
    display: flex;
    align-items: center;
}

#optiontop span.selBtn.deleted {
    display: none;
}

#optiontop span.selBtn:first-child {
    margin-right: 10px;
}

#optiontop span.selBtn p,
#optiontop span.selBtn span {
    display: block;
    margin: 0;
}

#optiontop span.selBtn span {
    width: 10px;
    height: 10px;
    background-image: url(../icons/x_dark.svg);
    margin-left: 10px;
    cursor: pointer;
}

#optiontop #chooseBG-top {
    background-color: #dcbdad;
    padding: 1px 15px;
    border-radius: 25px;
    display: flex;
    align-items: center;
    transition: background-color 150ms;
}

#optiontop #chooseBG-top:hover {
    background-color: #e9cec1;
}

#optiontop #chooseBG-top p {
    margin: 0;
    line-height: inherit;
}

#optiontop .topBtn {
    float: left;
    cursor: pointer;
}

#optiontop .topBtn#individual-top {
    margin-right: 40px;
}

#optiontop .topBtn>* {
    display: inline-block;
    line-height: 20px;
}

#optiontop .topBtn p:first-letter {
    text-transform: capitalize;
}

#optiontop .topBtn:hover>p,
#optiontop .topBtn.active>p {
    text-decoration: underline;
}

#optiontop .topBtn .icon {
    height: 20px;
    width: 20px;
    margin-bottom: -3px;
    margin-right: 10px;
}

#optiontop .topBtn svg {
    height: 25px;
    width: 25px;
    margin-right: 10px;
}

#optiontop .topBtn svg.icon-thin {
    stroke-width: "10";
    -webkit-text-stroke-color: #39b739;
}

#optiontop .icon.info {
    background-image: url(../icons/info.svg);
    height: 14px;
    width: 14px;
    cursor: help;
    margin-left: 15px;
    position: relative;
    top: 2px;
}

h4#reset {
    color: #D65B3D;
    cursor: pointer;
    text-transform: capitalize;
    display: none;
}

#optiontop #confirmReset {
    background-color: #f0f0f0;
    border: solid #939291 1px;
    border-radius: 16px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    width: fit-content;
    padding: 4px;
    opacity: 0;
    transition: opacity 150ms;
    pointer-events: none;
}
#optiontop #confirmReset.rstOn {
    opacity: 1;
    pointer-events: auto;
}

#optiontop #confirmReset span {
    width: 20px;
    height: 20px;
    display: table-cell;
    background-size: 60%;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 50%;

}

#optiontop #confirmReset span:first-child {
    background-color: #D65B3D;
    background-size: 50%;
    margin-right: 10px;
    background-image: url(../icons/x.svg);
    cursor: pointer;
}

#optiontop #confirmReset span:last-child {
    background-color: #39b739;
    background-image: url(../icons/checkmark1.svg);
    cursor: pointer;
}

#optiontop .icon.info:hover #infoCustomColor {
    display: block;
}

#optiontop #infoCustomColor {
    position: absolute;
    top: 25px;
    left: calc((-360px / 2) + (14px / 2));
    width: 320px;
    padding: 20px;
    background-color: #2A2A2A;
    color: white;
    text-align: center;
    z-index: 50;
    border-radius: 4px;
    display: none;
}

#optiontop #infoCustomColor:before,
#optiontop #confirmReset::before {
    content: "";
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    border-bottom: 5px solid #939291;
    border-top: 5px solid transparent;
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
    left: 50%;
}

#optiontop #customColors>* {
    display: inline-block;
}

#optiontop #chooseBG-top .icon {
    background-image: url(../icons/paint.svg);
}

#optiontop #palette-top .icon {
    background-image: url(../icons/palette.svg);
}

#optiontop #individual-top .icon {
    background-image: url(../icons/paintPalette.svg);
}

.icon {
    background-position: center;
    background-repeat: no-repeat;
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 1px #000000b3;
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px #00000000;
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 1px #00000000;
    }
}