.loadingScreen{
    height: 100%;
    width: 100%;
    position: absolute;
    background-color: #ffffffee;
    z-index: 500;
}
.loadingScreen.full{
    background-color: #ffffff;
    z-index: 1000;
}
.loadingScreen .spinner{
    -webkit-animation: spinAround .5s linear infinite;
    animation: spinAround .5s linear infinite;
    border-color: transparent transparent #d65b3e #d65b3e;
    border-radius: 60px;
    border-style: solid;
    content: "";
    display: block;
    height: 2em;
    width: 2em;
    position: absolute;
    top:50%;
    left: 50%;
    transform-origin: 0% 0%;
}
@keyframes spinAround{0%{transform:rotate(0deg)translate(-50%,-50%)}to{transform:rotate(359deg)translate(-50%,-50%)}}