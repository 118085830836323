#sideoptParent{
    width:363px;
    background-color: white;
    position: absolute;
    z-index:50;
    box-sizing: border-box;
    border-right:solid #DFDDDC 1px;
    height: 100%;
    transition: all 250ms;
    left:0;
    z-index: 999;
    overflow: auto;
}
#sideoptParent.toggled{
    left:-364px;
}

.BGopt{
    margin:20px;
    margin-top:0;
}
.BGopt h4{
    margin:15px 0;
}
.mtlOpt h4, .optionsCustomization h4{
    margin:15px 20px;
}
#sideoptParent h4::first-letter{
    text-transform: capitalize;
}
button.bgBtn{
    display: block;
    width:100%;
    margin-bottom:15px;
    background:white;
    border:1px #DFDDDC solid;
    border-radius: 8px;
    padding:15px;
    cursor: pointer;
    color:black;
}
button.bgBtn.active::after{
    content: "";
    background-image: url(../icons/checkmark1.svg);
    width: 14px;
    height: 14px;
    margin-top:0px;
    display: block;
    float: right;
    background-size: 100%;
    background-position: center;
    background-repeat: no-repeat;
}
button.bgBtn p{
    text-align: left;
    margin:0;
    float: left;
}
button.bgBtn span.circle{
    width: 20px;
    height: 20px;
    float: left;
    border-radius: 50%;
    margin-right: 20px;
    margin-top:-3px;
}
button.bgBtn span.circle.palette:not(:nth-child(4)){
    margin-right: -4px;
}
button.bgBtn span.circle.colorLight, div.toggleMenu .currentColor span.colorLight,div[data-type="other"] button.colorLight:not(.image){
    border:1px #DFDDDC solid;
    box-sizing: border-box;
}
div[data-type="coating"] .colorLight svg{
    stroke:#DFDDDC;
    stroke-width: 1;
    overflow: inherit;
    transition: stroke-width 250ms;
}
button.bgBtn *{
    pointer-events: none;
}
div.validation{
    display: flex;
    justify-content: flex-end;
}
div.validation button{
    background: #96a18f;
    border-radius:10px;
    border:0;
    cursor:pointer;
    padding: 12px 40px;
    font-size: 1em;
    text-transform: capitalize;
    transition: background 150ms;
}
div.validation button.cancel{    
    background:transparent;
    padding: 12px 20px;
    color:#8F8F8F;
}
div.validation button.accept{
    font-weight: bold;
    color:black;
}
div.validation button.accept:hover{
    background: rgb(187, 200, 179)
}
div.validation button.accept:hover,div.validation button.cancel:hover{
    text-decoration: underline;
}
/************** Toggle menu *****************/
.hide{
    display: none;
}
div.toggleMenu{
    border-bottom:1px #DFDDDC solid;
    box-sizing: border-box;
    transition: height 250ms;
    padding: 0 20px;
    cursor: pointer;
    overflow: hidden;    
}
/*div.toggleMenu:nth-child(2){
    border-top:1px #DFDDDC solid;   
}*/
h4+div.toggleMenu{
    border-top:1px #DFDDDC solid;   
}
div.toggleMenu.toggled .bottomToggleParent button,div[data-type='stemLength'].toggleMenu.toggled .brassOpt{
    height: 0;
    margin: 0;
    border-width:0;
    opacity:0;
}
div.toggleMenu.toggled .bottomToggleParent{
    grid-gap:0 12px;
}
div.toggleMenu.toggled .bottomToggleParent button svg{
    stroke-width:0;
}
div.toggleMenu.toggled .mtlBtn.active::after{
    opacity: 0;
}
div.toggleMenu.toggled .arrow{
    transform: rotate(0deg)!important;
}
div.toggleMenu p{
    margin: 0;
    line-height: 50px;
    float: left;
}
div.toggleMenu p::first-letter{
    text-transform: capitalize;
}
div.toggleMenu .currentColor span.arrow{
    background-image:url(../icons/arrow.svg);
    width:10px;
    height: 100%;
    float:right;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    margin-left:15px;
    transform: rotate(-180deg);
    transition: transform 150ms;
}
div.toggleMenu .currentColor{
    float:right;
    height: 100%;
}
div.toggleMenu .currentColor p{
    color:#8F8F8F;
    font-size: 0.8em;
    float:left;
    margin-right: 10px;
}
div.toggleMenu .currentColor span.circle{
    background-color:pink;
    height:12px;
    width: 12px;
    display: block;
    float:left;
    position: relative;
    top:50%;
    transform: translateY(-50%);
    border-radius: 50%;
}
.mtlOpt .validation, .optionsCustomization .validation{
    padding:20px;
    background-color: white;
    position: sticky;
    border-top:1px solid #DFDDDC;
    margin-top: -1px;
    bottom:0;
}
.topToggleParent,.bottomToggleParent{
    display: block;
    float: left;
    width: 100%;
    height: 100%;
}
.topToggleParent{
    height: 50px;
}
.bottomToggleParent{
    display: grid;
    grid-template-columns: repeat(auto-fill, 50px);
    grid-gap: 16px 10px;
    justify-content: space-between; 
    transition: all 250ms;
    height: 100%;
}
div[data-type='stemLength'] .bottomToggleParent{
    display: block;
    grid-template-columns:none;
}
div[data-type='stemLength'] .bottomToggleButtons{
    display: grid;
    grid-template-columns: repeat(auto-fill, 40px);
    grid-gap: 8px 12px;
    justify-content: space-between; 
    transition: all 250ms;
    height: 100%;
}
div[data-type='variations'] .bottomToggleParent{
    display: grid;
    grid-template-columns: repeat(auto-fill, 90px);
    grid-gap: 8px 12px;
}
.toggleMenu button.variation{
    width:90px;
    height:30px; 
    background-color: transparent;
    border-color:#e7e7e7;
    border-radius:5px;
}
.toggleMenu button.stem{
    width:40px;
    height:40px; 
    background-size:90%;
    background-color: transparent;
    border-color:#e7e7e7;
    border-radius:5px;
}
div[data-type='canopyType'] .bottomToggleParent{
    grid-template-columns: repeat(auto-fill, 60px);
}
.toggleMenu button.stem.active,.toggleMenu button.variation.active{
    border:#e7e7e7 solid 1px;
}
.toggleMenu button.canopy{
    width:60px;
    height:60px; 
    background-size:90%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border-color:#e7e7e7;
    border-radius:5px;
}
.toggleMenu button.canopy.active{
    border:#e7e7e7 solid 1px;
}
.toggleMenu button{
    border:0;  
    transition: all 250ms; 
}
.toggleMenu button.mtlBtn:last-child{
    margin-bottom:20px;
}
.toggleMenu button.mtlBtn{
    position: relative;
    cursor: pointer;
    padding: 0;
}
.toggleMenu[data-type="coating"] button.mtlBtn{
    width:50px;
    height: 29px;
    background-color: transparent!important;    
}
.toggleMenu button.mtlBtn.active::after{
    content: "";
    position: absolute;
    display: block;
    width:19px;
    height: 19px;
    top: -6px;
    right: -6px;
    background-image:url(../icons/checkmark2.svg);
    transition: opacity 250ms;
    background-repeat: no-repeat;
    background-position:center;
    background-size:80%;
}
.toggleMenu button.mtlBtn.variation.active::after{
    top: -9px;
    right: -9px;
}
.toggleMenu[data-type="other"] .bottomToggleParent{
    grid-template-columns: repeat(auto-fill, 50px);
}
.toggleMenu[data-type="other"] button.mtlBtn{
    width:50px;
    height: 50px;
    background-size: 100%;
    background-position: center;
}
.toggleMenu .brassOpt{
    transition: all 250ms;
    float: left;
    height: 40px;
    margin-top:-10px;
    margin-bottom:10px;
}