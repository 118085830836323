#onBoarding {
    position: absolute;
    top: 80px;
    bottom: 0;
    left: 0;
    right: 0;
    background: white;
    z-index: 1000;
    padding: 60px;
    overflow: hidden;
    opacity: 0.5;
}

#onBoarding #videoBG, #onBoarding .videoLoading{
    left:50%;
    top:50%;
    position: absolute;
    z-index: -1;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
    transform: translate(-50%, -50%);
    background-image: url(../../public/images/BG_LA.jpg);
    background-size:cover;
    background-position:center ;
    pointer-events: none;
}
#onBoarding .videoLoading{
    z-index: -2;
}
/* PROGRESS BAR */

#onBoarding div.progressBar {
    height: 0;
    overflow: hidden;
    transition: height 500ms;
}
#onBoarding div.progressBar.barVisible {
    height: 80px;
}

#onBoarding div.progressBar>div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 50px;

}

#onBoarding div.pb {
    width: 25px;
    height: 25px;
    background-color: white;
    border: 1px solid black;
    border-radius: 50%;
    text-align: center;
}

#onBoarding div.pb p {
    margin: 0;
    margin-top: 40px;
    font-weight: 100;
}

#onBoarding span.pbSep {
    display: block;
    height: 1px;
    width: 50px;
    background-color: black;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

#onBoarding div.pb.active {
    background-color: #96a18f;
}

#onBoarding div.pb.done {
    background-color: #dde3d9;
    cursor: pointer;
}

#onBoarding div.pb.disabled {
    background-image: linear-gradient(45deg, #dbdbdb 25%, #bdbdbd 25%, #bdbdbd 50%, #dbdbdb 50%, #dbdbdb 75%, #bdbdbd 75%, #bdbdbd 100%);
    background-size: 5.66px 5.66px;
    border-color:#7d7d7d;
    color:#b4b4b4;
}

/* STEPS */

#onBoarding div.step {
    width: calc(100% - 120px);
    height: calc(100% - 200px);
    position: absolute;
    /* overflow-x: hidden;
    overflow-y: auto; */
    background-color: #ffffff;
}
#onBoarding div.step.init {
    overflow-x: unset;
    overflow-y: unset;
}
#onBoarding div.step .init{
    position: absolute;
    transform: translate(-50%,-50%);
    left:50%;
    top:calc(50% + 40px);
}

#onBoarding div.step h2,
#onBoarding div.step h1 {
    margin: 0;
    margin-bottom: 70px;
    text-align: center;
    font-size: 3em;
    font-family: Kaisei !important;
    font-weight: 300;
}

#onBoarding div.step h1 {
    font-size: 4em;
    line-height: 70px;
    max-width: 600px;
    margin: 0 auto;
}

#onBoarding div.step p.text {
    font-size: 1.5em;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
}

#onBoarding div.step button.startButton {
    margin: 0;
    margin-top: 50px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    border: 0;
    color: white;
    background-color: black;
    font-size: 1.25em;
    font-weight: 600;
    padding: 18px 40px;
    border-radius: 12px;
    cursor: pointer;
    transition: background 0.15s;
}

#onBoarding div.step button.startButton:hover {
    background-color: #313131;
}

#onBoarding div.selectProduct {
    overflow-y: auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, 150px);
    grid-gap: 10px 10px;
    justify-content: space-between;
    max-height: calc(100% - 140px);
}

#onBoarding div.selectProduct button.btn {
    background-color: transparent;
    height: 120px;
    width: 150px;
    border: 0;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

#onBoarding div.selectProduct button.btn.active {
    border: #e7e7e7 solid 1px;
    position: relative;
    border-radius: 8px;
}

#onBoarding div.selectProduct button.btn.active::after {
    content: "";
    position: absolute;
    display: block;
    width: 19px;
    height: 19px;
    top: 6px;
    right: 6px;
    background-image: url(../icons/checkmark2.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: 80%;
    z-index: 100;
}

#onBoarding div.selectProduct button.btn:hover {
    outline-offset: -1px;
    outline: 1px silver solid;
    border-radius: 5px;
}

#onBoarding div.selectProduct button.btn img {
    max-height: 80px;
    max-width: 100%;
}

#onBoarding div.selectProduct button.btn h3 {
    text-transform: uppercase;
    font-size: 12px;
    color:black;
    font-weight: 600;
}

#onBoarding div.bigButtons {
    display: flex;
    justify-content: center;
}

#onBoarding div.bigButtons button {
    font-family: Kaisei !important;
    border: silver 1px solid;
    border-radius: 50px;
    padding: 120px 50px;
    width: 300px;
    font-size: 1.5em;
    background-color: transparent;
    cursor: pointer;
    transition: background 0.15s;
}

#onBoarding div.bigButtons button:first-child {
    margin-right: 45px;
}

#onBoarding div.bigButtons button:hover {
    background-color: #ededed;
}

#onBoarding div.filter {
    display: flex;
    align-items: center;
    position: absolute;
    top:7px;
}
#onBoarding div.filter h4 {
    font-weight: 300;
}



/* SCROLLBAR */

/* width */
#onBoarding ::-webkit-scrollbar,.env3d-side ::-webkit-scrollbar {
    width: 13px;
}
.popup ::-webkit-scrollbar {
    width: 23px;
    margin: 100px;
}

/* Track */
#onBoarding ::-webkit-scrollbar-track, .env3d-side ::-webkit-scrollbar-track {   
    background-color: #c0c0c0;
    box-shadow: inset 6px 0px 0px #ffffff, inset -6px 0px 0px #ffffff;
    height: 50px;
}
.popup ::-webkit-scrollbar-track {   
    background-color: #c0c0c0;
    box-shadow: inset 11px 1px 0px #ffffff, inset -11px -1px 0px #ffffff;
}

/* Handle */
#onBoarding ::-webkit-scrollbar-thumb,.popup ::-webkit-scrollbar-thumb ,.env3d-side ::-webkit-scrollbar-thumb {
    background: #545351;
    border-radius:9999px;
}
.popup ::-webkit-scrollbar-thumb {
    border: 5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;    
}

/* Handle on hover */
#onBoarding ::-webkit-scrollbar-thumb:hover,.popup ::-webkit-scrollbar-thumb:hover,.env3d-side ::-webkit-scrollbar-thumb:hover{
    background-color: #7d7b79;
}

.popup ::-webkit-scrollbar-track-piece:end {
    margin-bottom: -5px; 
}

.popup ::-webkit-scrollbar-track-piece:start {
    margin-top: -5px;
}