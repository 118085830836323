.popup{
    position: absolute;
    top:0;
    bottom:0;
    left:0;
    right:0;
    z-index: 99999;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    position: fixed;
}
.popup .popup-prompt-top h2{
    color: #333;
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.125;
    margin: 0;
    margin-bottom: 5px;
}
.popup .popup-prompt-top p{
    color: #404040;
    font-size: 1.25rem;
    font-weight: 200;
    line-height: 1.25;
    margin:0;
}
.popup .popup-prompt-top h2::first-letter,.popup .popup-prompt-top p::first-letter{
    text-transform: capitalize;
}
.popup .popup-bg{
    background-color: #0a0a0adb;
    position: absolute;
    width: 100%;
    height: 100%;
}
.popup .popup-quit{    
    position: absolute;
    top:20px;
    right:20px;
    width: 32px;
    height: 32px;
    cursor:pointer;
}
.popup .popup-quit:before,.popup .popup-quit:after{
    background-color: #0a0a0adb;
    content: "";
    display: block;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
    transform-origin: center center;
}
.popup .popup-quit:before{
    height: 2px;
    width: 50%;
}
.popup .popup-quit:after{
    height: 50%;
    width: 2px;
}
.popup .popup-prompt{
    position: relative;
    max-width: 800px;
    padding: 3rem;
    border: 1px solid #f1eae4;
    background-color: #fff;
    border-radius: 6px;
    box-shadow: 1px 1px 3px rgb(0 0 0 / 10%);
    color: #404040;
    display: block;
    max-height: calc(100vh - 150px);
    overflow: hidden;
    /* overflow-y: auto; */
}
.popup .separator{
    background-color: rgba(0,0,0,.2);
    height: 1px;
    border: none;
    display: block;
    margin: 1.5rem 0;
    
}

.popup .popup-prompt-parent{
    display: block;
    position: relative;
}
.env3d.popup.popup .popup-prompt{
    max-width: 900px;
}
.env3d.popup .popup-prompt-parent{
    display: flex;
    grid-gap: 10px;
    width:900px;
    flex-wrap: wrap;
    max-height: calc(100vh - 150px - 134px);
}

.popup .popup-prompt-parent.padding{
    padding-top:5px;    
    height: calc(100% - 85px);
    overflow-y: auto;
    overflow-x: hidden;
}
.env3d.popup .popup-prompt-parent>div:not(.react-pdf__Document){
    flex: 1 0 32%;
    max-width: calc(33% - 5px);
}
.popup .popup-prompt-parent>div:not(.react-pdf__Document){
    display: block;
    position: relative;
    cursor: pointer;    
    width:100%;
    height: 300px;
    overflow: hidden;
    box-sizing: border-box;
}
.popup .popup-prompt-parent>div.react-pdf__Document{
    height: calc(100%);
    width:100%;
    box-sizing: border-box;
}
.popup .popup-prompt-parent>div.active{
    border:3px solid #0a0a0adb;
}
.popup .popup-prompt-parent>div.active::after{
    content: "";
    background: none;
    border: 2px solid #fff;
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
}
.popup .popup-prompt-thumbnail{
    width:100%;
    height: 100%;
    opacity: 0.75;    
    transition: opacity 150ms;
    background-position: center;
    background-size:cover;
    background-repeat: no-repeat;
    overflow: hidden;
}
.popup .popup-prompt-parent h4{
    width:100%;
    opacity: 0.75;    
    background-color:#0a0a0adb;
    color:white;
    text-align: center;
    padding:10px 0;
    position: absolute;
    bottom:0;
    margin: 0;
}
.popup .popup-prompt-parent h4::first-letter{
    text-transform: capitalize;
}
.popup .popup-prompt-parent>div.disabled{
    cursor:not-allowed;
}
.popup .popup-prompt-parent>div:not(.disabled):hover .popup-prompt-thumbnail,
.popup .popup-prompt-parent>div.active .popup-prompt-thumbnail{
    opacity: 1;    
}
.popup .popup-prompt-parent>div.disabled .popup-prompt-thumbnail{
    opacity: 0.25;
}

.popup .popup-prompt-bottom{
    margin-top:20px;
}
.popup .popup-prompt-bottom button{
    background: none;
    border: 0;
    cursor: pointer;
    padding: 0;
    font-size: 1em;
    text-transform: capitalize; 
}
.popup .popup-prompt-bottom button.cancel{
    float: left;
    color: #8F8F8F;
}
.popup .popup-prompt-bottom button.accept{
    float: right;
}

/* -------------------------------------------------------------------- PDF */

.popup.pdf .popup-prompt{
    width:calc(100vh * (792/612) - 322px);
    max-height: calc(100% - 150px);
    max-width: none;
}
.popup.pdf .popup-prompt-parent{
    height: calc(100% - 100px);
}
.popup.pdf .popup-prompt-parent {
    box-sizing: border-box;
}
.popup.pdf .react-pdf__Page, .popup.pdf .react-pdf__Page>div{
    width:100%!important;
    height: 100%!important;
    text-align: center;
}
.popup.pdf canvas{
    width:auto!important;
    height: 100%!important;
    border:1px solid #00000033;
    box-sizing: border-box;
    margin: 0 auto;
}
.popup.pdf .react-pdf__Page__textContent.textLayer,.popup.pdf .react-pdf__Page__annotations.annotationLayer{
    display:none;
}
#root .popup.pdf .loadingScreen{
    height: 100%;
    border:solid 1px #00000033;
}
.popup.pdf .react-pdf__Page{
    min-width: auto!important;
    min-height: auto!important;
}
.popup.pdf .triangle{
    width:70px;
    height:70px;
    position: absolute;
    background-color: white;
    background: linear-gradient(225deg, rgba(255,255,255,1) 50%, #e6e6e6 50%, #f7f7f7 100%);
    box-shadow: -2px 2px 6px -1px rgba(0,0,0,0.1);
    z-index: 555;
    right:0;
    top:0;
    border-bottom:solid 1px #00000033;
    border-left:solid 1px #00000033;
}
.popup .popup-prompt-parent-material{
    margin:25px 0;
    display: flex;
    justify-content: space-between;    
}
.popup .popup-prompt-material{
    width:calc(50% - 40px);
    border:1px solid silver;
    border-radius:5px;
    padding:15px;
}
.popup .popup-prompt-material h3{
    font-size: 12px;
    margin: 0;
}
.popup .popup-prompt-material h3::first-letter{
    text-transform: capitalize;
}
.popup .popup-prompt-material .mtl:not(:first-child) h3{
    max-width: 80px;
    line-height: 12px;
}
.popup .popup-prompt-material h4{
    font-size: 8px;
    margin: 0;
    text-transform: uppercase;
    background-color: transparent;
    opacity: 1;
    width: auto;
    color: inherit;
    text-align: left;
    padding: 0;
    position: relative;
    font-weight: 300;
}
.popup .popup-prompt-material span{
    display: flex;
    align-items: center;
}
.popup .popup-prompt-material .mtl{
    display: grid;
    grid-template-columns: repeat(auto-fill, 110px);
    grid-gap: 5px 8px;
    justify-content: space-between;
}
.popup .popup-prompt-material .mtl:first-child{
    margin-bottom:15px;
}
.popup .popup-prompt-material .mtl>div{
    width: 110px;
}
.popup .popup-prompt-material .mtl h4+div{
    display: flex; 
    justify-content: space-between; 
    margin-top:2px;
}
.popup .popup-prompt-material .mtl h4+div span{
    width:12px;
    height: 12px;
    border:silver solid 1px;
    border-radius: 50%;   
}
.popup .popup-prompt-material span svg{
    width:30px;
    height:30px;
    margin-right:10px;
}
@media (orientation: portrait) {
    .popup.pdf canvas{
        height:auto!important;
        width: 100%!important;
    }
}


/* MUI */

.MuiFormLabel-root.Mui-focused {
    color: #2a2a2a;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #2a2a2a;
}
.popup-textfield .MuiFormHelperText-marginDense{
    top: -5px;
    position: relative;
}
.popup-textfield {
    width:calc(50% - 7.5px);
}
/* .popup-bottomButton{
     max-width:800px; 
} */
.popup-textfield.space{
    margin-right: 15px;
}

div[role='presentation']{
    z-index: 99999!important;
}
div.inline-flex{
    display: inline-flex;
    margin-bottom:24px;
}
.smallLabel .MuiInputLabel-outlined{
    transform: translate(14px, 12px) scale(1);
    
}
.smallLabel .MuiOutlinedInput-root.MuiInputBase-sizeSmall{
    padding-top:8px!important;
    padding-bottom:8px!important;

}
