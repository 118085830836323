#printPage{
    display: none;
}
@media print{
    @page {
        margin: 0;

    }
    .noprint{
        display: none;
        color:black;
    }
    body{
        -webkit-print-color-adjust:exact !important;
        print-color-adjust:exact !important;
        -webkit-text-size-adjust: none;
    }  
    body,html{
        height: 100%;

    } 
    * {
        box-sizing: border-box;
    } 
    
    #root div#printPage{
        background-color: white;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        font-size: 14px;
        display: block;
    }
    #printContent_top,#printContent_bottom{
        height: 100%;
        width: 100%;
        position: absolute;
    }
    #printPage a {
        color:black;
        text-decoration: none;
    }
    /*------------------------------ TOP */
    #printPage div#p-luminaire{
        width:100%;
        height:100%;
        top:0;
        left:50%;
        transform: translateX(-50%);
        position: relative;
        background-repeat:no-repeat;
        background-position: center;
        background-size:contain;
        border:0;
    }
    #printPage #p-top{
        position: absolute;
        top:0;
        width:100%;  
        height: 70%;
        margin: 0;
        padding-left:50px; 
        padding-right:50px;    
    }
    #printPage #p-copyrights{
        position: absolute;
        bottom:calc(100% + 10px);
    }
    #printPage #p-copyrights p{
        line-height: inherit;
        font-size:0.8vw;
        margin:0;
        padding: 0;
    }
    #printPage #p-copyrights p:first-child{
        text-transform: uppercase;
    }
    /*--------------------------- BOTTOM */
    #printPage p,#printPage h3,#printPage h4{
        margin:0;
        line-height: inherit;
    }
    #printPage #p-bottom{
        position: absolute;
        bottom:0;
        width:100%;
        margin:0;
        padding-left:50px;
        padding-right:50px;
        border-top:solid black 1px;
        border-bottom:solid black 1px;
        height: 30vh;
    }
    /* Left part */
    #printPage #p-bottom #bottomSections{
        display: flex;
        height: calc(30vh - 2px);
        width: 100%;

    }
    #printPage #p-bottom #s1{
        width:calc(35% + 20px);        
    }
    #printPage #p-bottom #s1 p, #printPage #p-bottom #s1 h3, #printPage #p-bottom #s1 a{
        display: block;    
    }
    #printPage #p-bottom #s1>div{
        height: 100%;
        width: 100%;    
        display: flex;
        align-items: center;
    }
    #printPage #p-bottom #s1>div>img{
        height:35%;
    }
    #printPage #p-bottom #s1>div div:not(.loveDiv){
        margin-left:20px;
    }
    #printPage #p-bottom #s1 div h3 {
        font-size:1.7vw;
    }
    #printPage #p-bottom #s1 div p,#printPage #p-bottom #s1 div a{
        font-size:1.25vw;
        color:black;
        text-decoration: none;
    }
    #printPage #p-bottom #s1 div div div:last-child{
        margin-top:2.5vw;
    }
    #printPage #p-bottom #s1 div div:last-child p{
        font-size:0.85vw;
    }
    #printPage #p-bottom #s1 div .love-icon{
        width:1.5vw;
        height:1.5vw;
        
        margin:-0.22vw 0.3vw;
    }
    #printPage #p-bottom #s1 .loveDiv{
        display: flex;
    }
    
    /* Middle part */
    #printPage #p-bottom #s2{
        border-left: 1px solid black;
        border-right: 1px solid black;
    }
    #printPage #p-bottom #s2{
        width:25%;
    }
    #printPage #p-bottom #s2>div{
        margin-top:20px;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }
    #printPage #p-bottom #s2>div>div{
        float: left;
    }
    #printPage #p-bottom #s2>div #s3{
        margin-left:3vw;
    }
    #printPage #p-bottom #s2>div>div>div:not(:first-child){
        margin-top: 0.2vw;
    }    
    #printPage #p-bottom #s2 h4{
        font-weight: 100;
        font-size:1vw;
        text-transform: capitalize;
    }
    #printPage #p-bottom #s2 div div{
        width:9vw;
        position: relative;
    }
    #printPage #p-bottom #s2 p{
        font-weight: 300;
        margin-top:-0.2vw;
        font-size:1.2vw;
        text-transform: capitalize;
        position: relative;
        max-width:8vw;
    }
    #printPage #p-bottom #s2>div span.p-pastille{
        width:1vw;
        height:1vw;
        border:#727272 solid 1px;
        display: block;
        /*margin-top:0.35vw;*/
        position: absolute;
        top:50%;
        right:0;
        transform: translateY(-50%);
        border-radius: 50%;
    }

    /* right part */
    #printPage #p-bottom #s3{
        display: flex;
        justify-content: space-between;
        padding-left:3vw;
        width: calc(40% - 20px);
        margin-top:20px;
    }
    
    #printPage #p-bottom #s3 h4{
        font-size: 1vw;
        font-weight: 100;
        margin: 0;
        text-transform: uppercase;
    }
    #printPage #p-bottom #s3 h4+p{
        font-size: 2vw;
        font-weight: 200;
        text-transform: uppercase;
        width: 11vw;
        overflow-wrap: break-word;
    }
    #printPage #p-bottom #s3 h5{
        font-size: 0.8vw;
        font-weight: 100;
        margin: 0;
        margin-top:2vw;
    }
    #printPage #p-bottom #s3 h5::first-letter{
        text-transform: capitalize;
    }
    #printPage #p-bottom #s3 h5+p{
        font-size: 1vw;
        font-weight: 200;
    }
    #printPage #p-bottom #s3 #p-QR{
        width:100%;
        height:100%;
    }
    #printPage #qrCodeDiv{        
        margin-left:20px;
        height: calc(100% - 40px);
    }


    /* ------------------------------------------------------------ Portrait ONLY */
    @media print and (orientation: portrait){
        
        #printPage #p-bottom #s1 img:not(.love-icon){            
            height: 10vw;
            margin-left:-20px ;
        }
        #printPage #p-bottom {
            padding-left: 20px;
            padding-right: 20px;
        }
        #printPage #p-bottom #s1 div div:not(.loveDiv){            
            margin-top: 20px ;
            margin-left:0;
        }
        #printPage #p-bottom #s1{
            width: 25%;
        }
        #printPage #p-bottom #s1>div{
            flex-direction: column;
            justify-content: center;
        }
        #printPage #p-bottom #s2{
            width: 30%;
        }
        #printPage #p-bottom #s3>div{
            float:none;
        }
        #printPage #p-bottom #s3 h4+p{
            width: 100%;
        }
        #printPage #p-bottom #s3 {            
            width: 45%;
        }
        #printPage #p-bottom #s3 {
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
        }
        #printPage #qrCodeDiv{
            width:100%;
            margin-top:20px;
            height: 45%;
            bottom:20px;
        }
    }

}


