div#topbar{
    background-color: #2A2A2A;
    height: 80px;
    width: calc(100% - 80px);
    position:relative;
    padding:0 40px;
    z-index: 1100;
}
div#topbar #logo{
    margin-right:30px;
}
div#topbar #logo img{
    height:40px;
    vertical-align: middle;
}
div#topbar a,div#topbar h1{
    margin:0 10px;
    height: 100%;
    display:inline-block;
    color:white;
    line-height: 80px;
    text-decoration: none;
}
div#topbar a::first-letter,div#topbar h1::first-letter{
    text-transform: capitalize;
}
.uppercase{
    text-transform: uppercase!important;
}
div#topbar h1{
    font-size:1em;
    font-weight: 200;
}
div#topbar #side{
    float: right;
}
div#topbar #side a{
    cursor: pointer;
    user-select: none;
}
div#topbar #side a:hover{
    text-decoration: underline;
}
div#topbar #side a.active{
    text-decoration: underline;
    font-weight: 900;
}