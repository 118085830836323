#bottombar{
    width: 100%;
    position: absolute;
    bottom:0;
    height: 100px;
    padding: 10px 50px;
    border-top: solid #DFDDDC 1px;
    box-sizing: border-box;
}
#bottombar #price *{
    margin: 0;
}
#bottombar #price h5{
    font-weight: 400;
    font-size: 0.8em;
    color:#8F8F8F;
}
#bottombar #price h6{
    font-weight: 800;
    font-size: 0.6em;
    color:#797979;
}
#bottombar #price h5::first-letter{
    text-transform: capitalize;
}
#bottombar #price p{
    font-weight: 400;
    font-size: 1.5em;
}
#bottombar div{
    float: left;
    position: relative;
    top:50%;
    transform: translateY(-50%);
}
#bottombar .bottomIcon{
    float: right;
    background-color: #2A2A2A;
    padding:0px 30px;
    color:white;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: background-color 150ms;
}
#bottombar .bottomIcon:hover{
    background-color: #424242;
}
#bottombar .bottomIcon:last-child{
    margin-right: 20px;
}
#bottombar .bottomIcon span.icon{
    background-size:99%;
    background-position: center;
    height: 26px;
    width:26px;
    margin-right: 10px;
    margin-bottom: -3px;
}
#bottombar #samplekit span.icon{
    background-image:url(../icons/samples.svg) ;
}
#bottombar .bottomIcon > *{
    display: inline-block;
    line-height: 20px;
}
#bottombar .bottomIcon p{
    margin-left:10px;
}
#bottombar .bottomIcon p:first-letter{
    text-transform: uppercase;
}

#price #notGoodPrice{
    color:rgb(211, 40, 40);
    font-size: 12px;
    margin-top:2px;
}