@font-face {
    font-family: WorkSans;
    src: url('../fonts/WorkSans-Regular.ttf');
    font-weight: 400;
}

#root.report{
    overflow: auto;
}
table {
    font-family: WorkSans, arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    color:#97a3be;
}

td,
th {
    border: 1px solid #1b1e20;
    text-align: left;
    padding: 8px;
}

tbody tr:nth-child(even) {
    background-color: #262a31;
}
tbody tr:nth-child(odd) {
    background-color: #373c46;
}
tbody tr.archived:nth-child(even):not(:hover) {
    background-color: #312626;
}
tbody tr.archived:nth-child(odd):not(:hover) {
    background-color: #463737;
}
tbody tr {
    transition: all 0.15s;
}
tbody tr:hover {
    box-sizing:border-box;
    background-color:rgb(66, 104, 169);
    color:white;
}
thead {
    background-color: #1a1d20;
    color:#6a737d;
    font-size: 20px;
    text-transform: uppercase;
    top:0;
    position: sticky;
}
tfoot {
    background-color: #1c1e21;
    text-transform: uppercase;
    bottom:0;
    font-size: 20px;
    position: sticky;
    color:white;
    width: 100%;
}
tfoot td{
    border-color:#090c0e;
}

tr.archived:hover{
    background-color: #762c2c;
}
tr.archived{
    color:#a16464;
    font-style: italic;
}
tr.archived td:first-child::after{
    content:" [ARCHIVED]";
}
tr.archived td:not(:first-child){
    text-decoration: line-through;
    
}

